module.exports={
    "pg": {
        "host"     : "127.0.0.1",
        "user"     : "waend",
        "password" : "plokplok",
        "database" : "waend0"

    },
    "cache": {
	"level": {
		"path": "/home/waend/waend_cache"
	},
        "redis": {
            "host": "127.0.0.1",
            "port": 6379
        }
    },
    "notify": {
        "url": "https://alpha.waend.com/notify"
    },
    "solr": {
        "host": "localhost",
        "port": 8983,
        "collection": "waend"
    },
    "server":{
        "port": 8000,
        "viewEngine" : "ejs",
        "mediaDir" : "/home/waend/media",
        "views" : "/home/waend/apps/waend/views",
"bodyParserLimit": "1000kb",
"session": {
            "redis": {
                "host": "127.0.0.1",
                "port": 6379
            },
            "secret": "206cb21aa02cbea6176fddfe728cbccb"
        }
    },
    "public":{
"baseUrl": "https://alpha.waend.com",
        "loginUrl" : "https://alpha.waend.com/login",
        "logoutUrl" : "https://alpha.waend.com/logout",
        "apiUrl" : "https://alpha.waend.com/api/v1",
        "mediaUrl" : "https://alpha.waend.com/media",
        "binUrl" : "https://alpha.waend.com/bin"
    }
}
